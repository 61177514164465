/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { userActions } from "../../redux/actions/userActions";
import { makeStyles } from '@mui/styles';
import { Drawer, Collapse } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import {     useLocation, Navigate, NavLink} from 'react-router-dom'

import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import GroupIcon from '@mui/icons-material/Group';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AppsIcon from '@mui/icons-material/Apps';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import img from '../../img/logo_min.png';
import ConfigCheck from './ConfigCheck';

import config from '../../config'
const drawerWidth = 240;


const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
	},
	appBar: {
		color: "white",
		backgroundColor: `${config.color} !important`,

	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,

	},

	menuButton: {
		marginRight: 36,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		width: drawerWidth,
		backgroundColor: `${config.color} !important`,
	},
	drawerClose: {
		backgroundColor: `${config.color} !important`,
		overflowX: 'hidden',
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		height: '64px !important'
	},
	itemToolBar: {
		color: "white",
	},
	content: {
		display: 'flex !important',
		backgroundColor: "#282828",
		color: "white",
		marginLeft: 240,
		padding: '20px',
		paddingTop: '30px',
		marginTop: 50,
		marginBottom: 100,
		position: "relative",
		textAlign: "center",
		alignItems: "center"
	},
}));


const Layout = (props) => {
	const {currUser, children, logout} = props;
	const classes = useStyles();
	const [submenu, setsubmenu] = useState(false);
	const [configOpen, setconfigOpen] = useState(false);
	const [islogged, setislogged] = useState(true);
	let location = useLocation();

	useEffect(() => {
		if (currUser && Object.keys(currUser).length === 0) {
			setislogged(false)
		}
		else {
			setislogged(true)
		}
	}, [currUser]);

	if (islogged) {
		return (
			<div>
				<AppBar
					position="fixed"
					className={classes.appBar}
				>
					<Toolbar>
						<Grid container style={{ width: '100%' }}>
							<Grid container item xs={12} justifyContent="flex-end">
								<Button onClick={() => logout()} style={{ backgroundColor: '#77a58d' }} variant="contained">Se deconnecter</Button>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				<Drawer
					variant="permanent"
					className={classes.drawerOpen}
					classes={{ paper: classes.drawerOpen }}
				>
					<div className={classes.toolbar}/>
					<Divider />
					<List className={classes.itemToolBar}>

						<ListItem button onClick={() => setsubmenu(!submenu)}>
							<ListItemIcon className={classes.itemToolBar} ><AccessTimeOutlinedIcon /></ListItemIcon>
							<ListItemText primary="Cron" />
							{submenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						</ListItem>
						<Collapse in={submenu} timeout="auto" unmountOnExit>
							<List component="div" style={{ marginLeft: '20px' }}>
								<ListItem button key={"app"} component={NavLink} to="/allapps">
									<ListItemIcon className={classes.itemToolBar} ><AppsIcon /></ListItemIcon>
									<ListItemText primary={"Applications"} />
								</ListItem>
								<ListItem button key={"task"} component={NavLink} to="/alltasks">
									<ListItemIcon className={classes.itemToolBar} ><AssignmentIcon /></ListItemIcon>
									<ListItemText primary={"Taches"} />
								</ListItem>
							</List>
						</Collapse>

						<ListItem button key={"client"} component={NavLink} to="/clients">
							<ListItemIcon className={classes.itemToolBar} ><GroupIcon /></ListItemIcon>
							<ListItemText primary={"Clients"} />
						</ListItem>
						<ListItem button key={"logs"} component={NavLink} to="/logs">
							<ListItemIcon className={classes.itemToolBar} ><FormatListNumberedIcon /></ListItemIcon>
							<ListItemText primary={"Autre logs"} />
						</ListItem>
						<ListItem
							button
							key={"check"}
							style={{ backgroundColor: '#77a58d' }}
							onClick={() => setconfigOpen(true)}
						>
							<ListItemIcon className={classes.itemToolBar} ><CheckBoxIcon /></ListItemIcon>
							<ListItemText primaryTypographyProps={{ style: { whiteSpace: "normal" } }} primary={"Vérifier la Configuration"} /><br />
						</ListItem>
					</List>
				</Drawer>
				<ConfigCheck
					configOpen={configOpen}
					setconfigOpen={setconfigOpen}
				/>
				<div className={classes.content}>
					{children }
				</div>
			</div>
		);
	}
	else {
		return <Navigate to="/login" state={{ from: location }} />;
	}
}

function mapStateToProps(state) {
	return ({ currUser: state.user })
}

const actionCreators = {
	logout: userActions.logout,
}

const connectedLayout = connect(mapStateToProps, actionCreators)(Layout)
export default connectedLayout