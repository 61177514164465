/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import "../../scss/Home.scss";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
    Grid, 
} from '@mui/material';

import { cronServices } from "../../redux/services/cronServices";


import { AiFillWarning } from 'react-icons/ai';
import { ImCross } from 'react-icons/im';
import { BsCheck2All } from 'react-icons/bs';

const ConfigCheck = (props) => {
    const { configOpen, setconfigOpen } = props;
	const [repet, setrepet] = useState([]);

	useEffect(() => {
        cronServices.checkConfig()
        .then((data) => {
            const finalList = []
            data.forEach(problem => {
                const icon = [];
                if (problem.type === 'error') {
                    icon.push(<ImCross color="red" size={24}/>)
                } else if (problem.type === 'warning') {
                    icon.push(<AiFillWarning color="#edad00" size={24}/>)
                } else {
                    icon.push(<BsCheck2All color="green" size={24}/>)
                }

                finalList.push(<Grid container spacing={2}> 
                    <Grid item xs={2}>
                        {icon}
                    </Grid>
                      <Grid item xs={3}>
                      {problem.name}
                  </Grid>
                    <Grid item xs={7}>
                    {problem.message}
                </Grid></Grid>)
            })
            if (finalList.length === 0 ) {
                setrepet(<div> Aucune erreur trouvée</div>);
            }
            else {
                setrepet(finalList);
            }
        })
	}, [props.configOpen])

	return (
		<>
			<Dialog
				open={configOpen}
				onClose={() => setconfigOpen(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Vérification de la configuration"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<ul>{repet}</ul>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() =>  setconfigOpen(false)} autoFocus>
						Ok
					</Button>
				</DialogActions>
			</Dialog>

		</>)
}

function mapStateToProps(state) {
	const token = state.authentication.auth.access_token
	return { accessToken: token }
}

export default connect(mapStateToProps, {})(ConfigCheck);