import Cookies from 'js-cookie'
import { apiClient, handleResponse } from "./axios";

export const userServices = {
    login,
    logout,
    generateLoginToken,
    getclients,
};

function login(body) {
    return apiClient.post(`/user/login`, body)
        .then(data => {
            return data
        })
}

function getclients() {
    return apiClient.get(`/clients`)
        .then(handleResponse)
        .then(data => {
            return data
        })
}

function generateLoginToken(auth) {
    if (auth !== undefined) {
        if (auth.data && auth.data.token)
            Cookies.set('jwt', auth.data.token, { expires: auth.expires_in }, { secure: true }, { sameSite: 'strict' })
    }
}

function logout() {
    localStorage.clear();
    sessionStorage.clear();
    Cookies.remove('jwt');
    window.location.reload();
}