import { apiClient, handleResponse } from "./axios";

export const taskServices = {
    getAllTask,
    createTask,
    getSpecificTask,
    updateTask, 
    deleteTask
};

function getAllTask() {
    return apiClient.get(`/tasks`)
        .then(handleResponse)
        .then(data => {
            return data
        })
}


function createTask(body) {
    return apiClient.post(`/tasks`, body)
        .then(handleResponse)
        .then(data => {
            return data
        })
}
  
function getSpecificTask(id) {
  return apiClient.get(`/tasks/${id}`)
      .then(handleResponse)
      .then(data => {
          return data
      })
}

function updateTask(id, body) {
  return apiClient.put(`/tasks/${id}`, body)
      .then(handleResponse)
      .then(data => {
          return data
      })
}


function deleteTask(id) {
    return apiClient.delete(`/tasks/${id}`)
        .then(handleResponse)
        .then(data => {
            return data
        })
  }