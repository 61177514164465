import { apiClient, handleResponse } from "./axios";

export const logsServices = {
  getAllOtherLogs,
  getLogsAppTask
};

function getLogsAppTask(appid, taskid) {

  let finalbody = {}
  if (appid) {
    finalbody['appid'] = appid;
  }
  if (taskid) {
    finalbody['taskid'] = taskid;
  }
  return apiClient.post(`/logs`, finalbody)
      .then(handleResponse)
      .then(data => {
          return data
      })
}

function getAllOtherLogs() {
  return apiClient.get(`/logs/allother`)
      .then(handleResponse)
      .then(data => {
          return data
      })
}