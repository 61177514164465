import React, { useEffect, useState } from "react";
import tableIcons from "../../helpers/materialTableIcons";
import MaterialTable from "material-table";
import moment from 'moment'; 
import { logsServices } from "../../redux/services/logsServices";

const Logs = () => {

    const [cronlogs, setcronlogs] = useState([]);

    useEffect(() => {
        logsServices.getAllOtherLogs().then((ret) => {
            setcronlogs(ret);
        });
    }, []);

    return (<MaterialTable
        columns={[
            { title: 'Message', field: 'message' },
            {
                title: 'Date / Heure', field: 'createdat', render: (rowData) => {
                    return (moment(rowData.createdat).format("DD/MM/YYYY HH:mm"))
                }
            },
        ]}
        data={cronlogs}
        title="Autres logs"
        icons={tableIcons}
        options={{
            pageSize: 20,
            pageSizeOptions: [20, 50, 100, 200]
        }}
    />);
}

export default (Logs);