export const userConstants = {

	LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
	LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
	LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

	AFTERLOGIN: 'USERS_AFTERLOGIN',
	UNLOAD_USER: 'USERS_UNLOAD_USER',
	
	LOGOUT: 'USERS_LOGOUT',
};