import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import "../../scss/Home.scss";
import { Link } from "react-router-dom";
import { userServices } from "../../redux/services/userServices";
import MaterialTable from 'material-table'
import tableIcons from "../../helpers/materialTableIcons";
import Visibility from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	Slide
} from '@mui/material';
import config from "../../config";
import { localeFr } from "../../helpers/utils";
import { cronServices } from "../../redux/services/cronServices";
import { toastError, toastSuccess } from "../../redux/actions/alertActions";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const Clients = () => {
	const [data, setdata] = useState([]);
	const [details, setdetails] = useState([]);
	const [detailDialog, setdetailDialog] = useState(false);
	const [dialogmail, setdialogmail] = useState(false)
	const [selectedPortal, setselectedPortal] = useState(null);

	useEffect(() => {
		userServices.getclients()
			.then(ret => {
				if (ret) setdata(ret);
			})
	}, [])

	const closeDialogMail = () => {
		setselectedPortal(null);
		setdialogmail(false);
	}

	const sendMailRequest = () => {
		if(selectedPortal) {
			cronServices.sendCronTask(selectedPortal).then((ret) => {
				if (!ret.error) toastSuccess(ret);
				else toastError(ret.error);
				closeDialogMail();
			})
		}
	}

	return (
		<>
			<div style={{ maxWidth: '100%' }}>
				<MaterialTable
					columns={[
						{ title: 'Client Id', field: 'etab_id' },
						{ title: 'Nom', field: 'etab_rs' },
						{
							title: 'URL', field: 'heberge_url', render: (rowdata) => {
								return (<Link to={{ pathname: rowdata.heberge_url ? rowdata.heberge_url : '#' }} target="_blank" >{rowdata.heberge_url}</Link>)
							}
						},
						{ title: 'Etab code', field: 'etab_code' },
					]}
					data={data}
					title="Clients"
					localization={localeFr}
					icons={tableIcons}
					options={{
						pageSize: 25,
						pageSizeOptions: [25, 50, 100, 200]
					}}
					actions={[{
						icon: () => <Visibility />,
						tooltip: 'Details de l\'entreprise',
						onClick: (event, rowData) => {
							const finalRes = [];
							for (const key in rowData) {
								if (key !== 'tableData')
									finalRes.push({ type: key, value: rowData[key] })
							}
							setdetails(finalRes);
							setdetailDialog(true);
						}
					}
					]}
				/>

			</div>

			<Dialog
				fullScreen
				open={detailDialog}
				onClose={() => setdetailDialog(false)}
				TransitionComponent={Transition}
			>
				<AppBar sx={{ position: 'relative' }} style={{backgroundColor: config.color}}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={() => setdetailDialog(false)}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							Details
						</Typography>
					</Toolbar>
				</AppBar>
				<MaterialTable
					columns={[
						{ title: 'Type', field: 'type' },
						{ title: 'Valeur', field: 'value' },
					]}
					style={{ padding: '80px 50px 50px 50px' }}
					data={details}
					localization={localeFr}
					title=""
					icons={tableIcons}
					options={{
						pageSize: 50,
						pageSizeOptions: [50, 100, 200]
					}}
				/>

			</Dialog>
			<Dialog
				open={dialogmail}
				onClose={() => closeDialogMail(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Executer la tache cron mail sur le portail ?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
					Voulez vous executer la tache cron mail sur le serveur ?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => closeDialogMail(false)}>Annuler</Button>
					<Button onClick={() => sendMailRequest()} autoFocus>
						Ok
					</Button>
				</DialogActions>
			</Dialog>

		</>)
}

function mapStateToProps(state) {
	const token = state.authentication.auth.access_token
	return { accessToken: token }
}

export default connect(mapStateToProps, {})(Clients);