import { apiClient, handleResponse } from "./axios";

export const appServices = {
    getAllApp,
    createApps,
    getSpecificApp,
    updateApp,
    deleteApp
};

function getAllApp() {
    return apiClient.get(`/apps`)
        .then(handleResponse)
        .then(data => {
            return data
        })
}


function createApps(body) {
    return apiClient.post(`/apps`, body)
        .then(handleResponse)
        .then(data => {
            return data
        })
}
  
function getSpecificApp(id) {
  return apiClient.get(`/apps/${id}`)
      .then(handleResponse)
      .then(data => {
          return data
      })
}

function updateApp(id, body) {
  return apiClient.put(`/apps/${id}`, body)
      .then(handleResponse)
      .then(data => {
          return data
      })
}


function deleteApp(id) {
    return apiClient.delete(`/apps/${id}`)
        .then(handleResponse)
        .then(data => {
            return data
        })
  }