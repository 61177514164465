import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import "../scss/Home.scss";

import { logsServices } from "../redux/services/logsServices";
import MaterialTable from 'material-table'
import tableIcons from "./materialTableIcons";

import CloseIcon from "@mui/icons-material/Close";
import {
	Dialog,
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	Slide
} from '@mui/material';
import moment from 'moment'
import config from "../config";
import { localeFr } from "./utils";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const DialogCron = (props) => {

	const { cronLogsDialog, setcronLogsDialog, appid, taskid } = props;
	const [cronLogs, setcronLogs] = useState([]);

	useEffect(() => {
		if (appid || taskid) {
			logsServices.getLogsAppTask(appid, taskid).then((data) => {
				setcronLogs(data);
			})
		}
	}, [appid, taskid])



	return (
		<>
			<Dialog
				fullScreen
				open={cronLogsDialog}
				onClose={() => setcronLogsDialog(false)}
				TransitionComponent={Transition}
			>
				<AppBar sx={{ position: 'relative' }} style={{ backgroundColor: config.color }}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={() => setcronLogsDialog(false)}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							Details logs cron
						</Typography>
					</Toolbar>
				</AppBar>
				<MaterialTable
					columns={[
						{ title: 'Code status', field: 'statuscode' },
						{ title: 'Message', field: 'message' },
						{
							title: 'Date / Heure', field: 'createdat', render: (rowData) => {
								return (moment(rowData.createdAt).format("DD/MM/YYYY HH:mm"))
							}
						},
					]}
					style={{ padding: '80px 50px 50px 50px' }}
					localization={localeFr}
					data={cronLogs}
					title=""
					icons={tableIcons}
					options={{
						pageSize: 50,
						pageSizeOptions: [50, 100, 200],
						rowStyle: rowData => ({
							backgroundColor: (rowData.statuscode === 200) ? '#e0ffe0' : '#ffb2b2'
						})
					}}
				/>

			</Dialog>
		</>)
}

function mapStateToProps(state) {
	const token = state.authentication.auth.access_token
	return { accessToken: token }
}

export default connect(mapStateToProps, {})(DialogCron);