/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import { toastError } from '../../../redux/actions/alertActions';
import { userActions } from '../../../redux/actions/userActions';
import config from '../../../config';

const useStyles = makeStyles(() => ({
  textField: {
    width: '100% !important',
    marginTop: '15px',
  },
  gridField: {
    width: '100% !important',
    marginTop: '15px !important',
  },
  input: {
    color: 'white',
  },
  rootField: {
    background: 'white',
  },
  connectButton: {
    background: `${config.color}`,
    color: 'white',
    "&:hover": {
      background: `${config.colorTwo}`
    },
  }
}));

const LogInForm = (props) => {
  const { login } = props;
  const [email, setemail] = useState('');
  const [showPassword, setshowPassword] = useState(false);
  const [password, setpassword] = useState('');
  const classes = useStyles();

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    if (email && password) {
      login({
        user: email,
        pass: password,
      });
    } else {
      toastError('Merci de fournir un email et un mot de passe');
    }
  };

  return (
    <>
      <Grid container  justifyContent="center"
        alignItems="center" spacing={2}  direction="column">
        <Grid xs="12" >
          <Typography component="h4" variant="h6">
            S'identifier
          </Typography>
        </Grid>
        <Grid xs="12" className={classes.gridField} >
          <FormControl className={classes.textField} variant="outlined">
            <TextField
              id="outlined-adornment-password"
              type="text"
              variant="outlined"
              value={email}
              className={classes.rootField}
              onChange={(e) => setemail(e.target.value)}
              label="Email" 
              InputProps={{
                className: classes.input,
              }}
            />
          </FormControl>
        </Grid>
        <Grid xs="12" className={classes.gridField}>

        <FormControl  className={classes.textField} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Mot de passe</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword? 'text' : 'password'}
            value={password}
            onChange={(e) => setpassword(e.target.value)}
            className={classes.rootField}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setshowPassword(!showPassword)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Mot de passe"
          />
        </FormControl>
        </Grid>
        <Grid xs="12" className={classes.gridField}>
          <Button className={classes.connectButton} onClick={() => handleSubmit()} variant="contained" style={{ width: '100%', marginTop: '20px' }}>
            S'identifier
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

LogInForm.propTypes = {
  changeTab: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
};

function mapState(state) {
  const {
    loggingIn,
    codeLoggingIn,
    requestNewPassword,
    newPassword,
  } = state.authentication;
  return {
    loggingIn,
    codeLoggingIn,
    requestNewPassword,
    newPassword,
  };
}

const actionCreators = {
  login: userActions.login,
  forgotPassword: userActions.forgotPassword,
  logout: userActions.logout,
};

const connectedLogin = connect(mapState, actionCreators)(LogInForm);

export default reduxForm({
  form: 'log_in_form',
})(connectedLogin);
