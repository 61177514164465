/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import "../../scss/Home.scss";
import { Link } from "react-router-dom";
import { userServices } from "../../redux/services/userServices";
import MaterialTable from 'material-table';
import tableIcons from "../../helpers/materialTableIcons";
import {
	IconButton,
	Switch
} from '@mui/material';
import DialogCron from "../../helpers/dialogResumeCron";
import ListIcon from "@mui/icons-material/List";
import { localeFr } from "../../helpers/utils";
import { appServices } from "../../redux/services/appsServices";
import { taskServices } from "../../redux/services/tasksServices";
import { toastError, toastSuccess } from "../../redux/actions/alertActions";
import { TextField, Box } from '@mui/material';
import { AiFillCheckCircle } from 'react-icons/ai'
import { MdCancel } from 'react-icons/md'
import makeAnimated from 'react-select/animated';
import { formatRoute } from "../../helpers/utils";
import Select from "react-select";


const animatedComponents = makeAnimated();

const AllAps = () => {
	const [data, setdata] = useState([]);
	const [allTask, setallTask] = useState([]);
	const [cronlogsdialog, setcronlogsdialog] = useState(false);
	const [selectedtask, setselectedtask] = useState(null);
	const [selectedapp, setselectedapp] = useState(null);

	const initData = async () => {
		const finalTasks = [];
		const allTasks = await taskServices.getAllTask();

		if (allTasks) {
			allTasks.forEach(elem => {
				finalTasks.push({ label: elem['name'], value: elem['id'].toString() })
			})
			setallTask(finalTasks);
		}

		const allApps = await appServices.getAllApp()
		if (allApps) {
			allApps.forEach((elem, key) => {
				const ids = JSON.parse(elem['task']);
				const valueselected = []
				if (ids && ids['task']) {
					finalTasks.forEach((task) => {
						if (ids['task'].includes(task['value'])) {
							valueselected.push(task);
						}
					})
				}
				allApps[key]['task'] = valueselected;
			});
			setdata(allApps);
		}

	}

	useEffect(() => {
		initData();
	}, [])


	return (
		<>
			<div style={{ maxWidth: '100%' }}>
				<MaterialTable
					columns={[
						{ title: 'Nom', field: 'name' },
						{
							title: 'Url multiple', field: 'ismultiple',
							render: (rowdata) => {
								return rowdata.ismultiple ?
									<AiFillCheckCircle size={32} color="green" /> : <MdCancel size={32} color="red" />;
							},
							initialEditValue: 0,
							editComponent: props => (
								<Switch
									checked={props.value}
									onChange={e => props.onChange(e.target.checked === true ? 1 : 0)}
									inputProps={{ 'aria-label': 'controlled' }}
								/>
							),
						},
						{
							title: 'Url', field: 'url',
							render: (rowdata) => {
								return (<div>{rowdata.url}</div>)
							},
							editComponent: props => {
								return (
									<TextField
										id="filled-number"
										type="text"
										placeholder="url"
										value={props.value}
										onChange={(e) => {
											props.onChange(e.target.value)
										}}
										InputLabelProps={{ shrink: true }}
										variant="standard"
									/>
								)
							}
						},
						{
							title: 'Taches activées', field: 'task',
							render: (rowdata) => {
								const res = []
								if (rowdata.task.length > 0) {
									rowdata.task.forEach(elem => {
										res.push((<div>
											<IconButton>
												<ListIcon onClick={() => {
													setselectedapp(rowdata.id)
													setselectedtask(elem.value);
													setcronlogsdialog(true);
												}}
												/>
											</IconButton>

											{elem['label']}</div>))
									})
								}
								return (res);
							},
							editComponent: props => (
								<Select
									closeMenuOnSelect={false}
									components={animatedComponents}
									defaultValue={props.value}
									isMulti
									onChange={(e) => {
										props.onChange(e);
									}}
									options={allTask}
								/>
							),
						},
						{
							title: 'Application active ?', field: 'active',
							render: (rowdata) => {
								return rowdata.active ?
									<AiFillCheckCircle size={32} color="green" /> : <MdCancel size={32} color="red" />;
							},
							initialEditValue: 1,
							editComponent: props => (
								<Switch
									checked={props.value}
									onChange={e => props.onChange(e.target.checked === true ? 1 : 0)}
									inputProps={{ 'aria-label': 'controlled' }}
								/>
							),
						},
					]}
					data={data}
					title="Toutes les applications"
					localization={localeFr}
					icons={tableIcons}
					options={{
						pageSize: 10,
						pageSizeOptions: [10, 25, 50, 100, 200]
					}}
					actions={[
						{
							icon: () => <ListIcon />,
							tooltip: 'Detail des crons',
							onClick: (event, rowData) => {
								setselectedapp(rowData.id);
								setselectedtask(null);
								setcronlogsdialog(true);
							}
						},

					]}
					editable={{
						onRowAdd: newData =>
							new Promise((resolve, reject) => {
								if (newData['name']) {
									if (newData['task']) {
										const finalTaskIds = []
										newData['task'].forEach((elem) => {
											finalTaskIds.push(elem['value']);
										})
										newData['task'] = JSON.stringify({ task: finalTaskIds });
									}
									appServices.createApps(newData)
										.then(() => {
											initData();
											toastSuccess('Application créée');
											resolve();
										})
								}
								else {
									toastError('Tous les champs ne sont pas remplis');
									reject()
								}

							}),
						onRowUpdate: (newData, oldData) =>
							new Promise((resolve) => {
								const finalTaskIds = []
								newData['task'].forEach((elem) => {
									finalTaskIds.push(elem['value']);
								})
								newData['task'] = JSON.stringify({ task: finalTaskIds });

								appServices.updateApp(oldData.id, newData)
									.then(() => {
										initData();
										resolve();
									});
							}),
						onRowDelete: oldData =>
							new Promise((resolve) => {
								appServices.deleteApp(oldData.id)
									.then(() => {
										const dataDelete = [...data];
										const index = oldData.tableData.id;
										dataDelete.splice(index, 1);
										setdata([...dataDelete]);
										resolve();
									})
							}),
					}}
				/>
				<DialogCron cronLogsDialog={cronlogsdialog} setcronLogsDialog={setcronlogsdialog} appid={selectedapp} taskid={selectedtask} />
			</div>
		</>)
}

function mapStateToProps(state) {
	const token = state.authentication.auth.access_token
	return { accessToken: token }
}

export default connect(mapStateToProps, {})(AllAps);