import { userConstants } from '../constants/userConstants';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';

let token = Cookies.get('jwt');

let initialState = {};
if (token && token !== undefined) {
	const decoded = jwt_decode(token);
	initialState = decoded;
} else {
	initialState = {};
}

export default function user(state = initialState, action) {
	switch (action.type) {
		case userConstants.AFTERLOGIN:
			return action.user
		case userConstants.UNLOAD_USER:
			return {};
		default:
			return state
	}
}