import { combineReducers, createStore, applyMiddleware } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import {
    userReducer,
    authenticationReducer,
    sidebarReducer,
    alertReducer
} from '../redux/reducers/index';
import thunk from 'redux-thunk';
//import logger from 'redux-logger';

//const middlewares = [thunk, logger];
const middlewares = [thunk];

const appReducer = combineReducers({
    alert: alertReducer,
    form: reduxFormReducer, // mounted under "form",
    user: userReducer,
    authentication: authenticationReducer,
    sidebar: sidebarReducer,
});

const rootReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === 'USERS_LOGOUT') {
        state = undefined;
    }

    return appReducer(state, action);
};

const store = createStore(rootReducer, applyMiddleware(...middlewares));

export default store;