/* eslint-disable no-console */
import { userConstants } from "../constants/userConstants";
import { userServices } from '../services/userServices';
import { toastError, toastSuccess } from './alertActions';
import jwt_decode from "jwt-decode";

export const userActions = {
	login,
	logout,
};

function logout() {
	return dispatch => {
		userServices.logout();
		dispatch(userlogout());
	}
	function userlogout() { return { type: userConstants.LOGOUT } }
}

function login(body) {
	return dispatch => {
		dispatch(loginRequest({ body }));
		return userServices.login(body)
			.then(
				logged => {
					if (logged && logged.status && logged.status === 200) {
						const decoded = jwt_decode(logged.data.token);
						dispatch(userSuccess(decoded))
						userServices.generateLoginToken(logged);
						dispatch(loginSuccess({ loggedIn: true, jwt: logged.data.token }))
						toastSuccess('Connexion réussie');
					}
					else {
						toastError(logged.message);
					}
				},
				error => {
					dispatch(loginFailure(error.toString()));
					toastError(error.toString());
				}
			)
			.catch((error) => {
				console.error(error);
			})
	};

	function loginRequest() { return { type: userConstants.LOGIN_REQUEST } }
	function loginSuccess(auth) { return { type: userConstants.LOGIN_SUCCESS, auth } }
	function userSuccess(user) { return { type: userConstants.AFTERLOGIN, user } }
	function loginFailure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}