import { apiClient, handleResponse } from "./axios";

export const cronServices = {
  getAllCronTask,
  sendCronTask,
  checkConfig
};

function getAllCronTask() {
  return apiClient.get(`/cron`)
      .then(handleResponse)
      .then(data => {
          return data
      })
}

function sendCronTask(id) {
  return apiClient.get(`/cron/execute/${id}`)
      .then(handleResponse)
      .then(data => {
          return data
      })
}

function checkConfig() {
  return apiClient.get(`/cron/check`)
  .then(handleResponse)
  .then(data => {
      return data
  })
}

