import React from "react";
import {Box } from '@mui/material';

export const localeFr = {
    body: {
      emptyDataSourceMessage: "Aucun enregistrement à afficher",
      addTooltip: 'Ajouter',
      deleteTooltip: 'Supprimer',
      editTooltip: 'Editer',
      filterRow: {
        filterTooltip: 'Filtrer'
      },
      editRow: {
        deleteText: 'Etes vous sur de vouloir supprimer cette entrée ?',
        cancelTooltip: 'Annuler',
        saveTooltip: 'Enregistrer'
      }
    },
    grouping: {
      placeholder: "Tirer l'entête ...",
      groupedBy: 'Grouper par:'
    },
    header: {
      actions: 'Actions'
    },
    pagination: {
      labelDisplayedRows: '{from}-{to} de {count}',
      labelRowsSelect: 'lignes',
      labelRowsPerPage: 'lignes par page:',
      firstAriaLabel: 'Première page',
      firstTooltip: 'Première page',
      previousAriaLabel: 'Page précédente',
      previousTooltip: 'Page précédente',
      nextAriaLabel: 'Page suivante',
      nextTooltip: 'Page suivante',
      lastAriaLabel: 'Dernière page',
      lastTooltip: 'Dernière page'
    },
    toolbar: {
      addRemoveColumns: 'Ajouter ou supprimer des colonnes',
      nRowsSelected: '{0} ligne(s) sélectionée(s)',
      showColumnsTitle: 'Voir les colonnes',
      showColumnsAriaLabel: 'Voir les colonnes',
      exportTitle: 'Exporter',
      exportAriaLabel: 'Exporter',
      exportName: 'Exporter en CSV',
      searchTooltip: 'Rechercher',
      searchPlaceholder: 'Rechercher'
    }
  };

export const formatRoute = (initialRoute) => {
  let pathElem = [];
  if (initialRoute) {
    pathElem = initialRoute.split("/");
  }

  return (<Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      p: 0,
      m: 0,
    }}
  >
    <div style={{color: 'red'}}>/</div>
    {pathElem.map((elem) => {
      if (elem) {
        return (<><div>{elem}</div>
          <div style={{color: 'red'}}>/</div></>)
      }
      return (<></>)
    })}
  </Box>)
};