/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import "../../scss/Home.scss";
import MaterialTable from 'material-table';
import tableIcons from "../../helpers/materialTableIcons";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { TextField, Dialog, DialogActions, DialogContent, DialogContentText, Button, DialogTitle, IconButton } from '@mui/material';
import TimePicker from '@mui/lab/TimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ListIcon from "@mui/icons-material/List";

import { localeFr } from "../../helpers/utils";
import { taskServices } from "../../redux/services/tasksServices";
import { toastError, toastSuccess } from "../../redux/actions/alertActions";
import DialogCron from "../../helpers/dialogResumeCron";

import { HiInformationCircle } from 'react-icons/hi';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import moment from "moment";
import { formatRoute } from "../../helpers/utils";




const ListHeures = (props) => {
	const { data } = props
	let allHours = []

	if (!data.value || data.value === 'null') {
		allHours = []
	}
	else if (typeof (data.value) === 'string') {
		allHours = JSON.parse(data.value)['hours']
	}
	else {
		allHours = data.value
	}

	const AddHours = () => {
		data.onChange([...allHours, { debut: '00:00', fin: '00:00' }]);
	}

	const removeHours = (key) => {
		allHours.splice(key, 1);
		data.onChange(allHours);
	}

	const updateHours = (key, isDebut, value) => {
		allHours[key][isDebut ? 'debut' : 'fin'] =moment(value).format('HH:mm');
		data.onChange(allHours);
	}


	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<List dense={true}>
				{allHours.map((elem, key) => (
					<ListItem
						key={key}
						secondaryAction={
							<IconButton edge="end" aria-label="delete">
								<DeleteIcon onClick={() => removeHours(key)} />
							</IconButton>
						}
					>
						<TimePicker
							label="Debut"
							ampm={false}
							value={moment(elem['debut'], 'HH:mm')}
							onChange={(e) => updateHours(key, true, e)}
							renderInput={(params) => <TextField {...params} />}
						/>
						<TimePicker
							label="Fin"
							ampm={false}
							value={moment(elem['fin'], 'HH:mm')}
							onChange={(e) => updateHours(key, false, e)}
							renderInput={(params) => <TextField {...params} />}
						/>
					</ListItem>)
				)}
			</List>
			<IconButton edge="add" aria-label="add">
				<AddIcon onClick={() => AddHours()} />
			</IconButton>
		</LocalizationProvider>
	)
}

const AllTasks = () => {
	const [data, setdata] = useState([]);
	const [dialog, setdialog] = useState(false);
	const [repet, setrepet] = useState([]);
	const [cronlogsdialog, setcronlogsdialog] = useState(false);
	const [selectedtask, setselectedtask] = useState(null);

	useEffect(() => {
		taskServices.getAllTask()
			.then(ret => {
				if (ret) setdata(ret);
			})
	}, [])

	const openResumeModal = (num) => {
		const numsecond = 3600 * 24;
		const secondbetween = Math.floor(numsecond / num);

		const allhours = [];
		for (let i = 0; i < num; i++) {
			const newval = moment().set("hour", 0).set("minute", 0).add(i * secondbetween, 'seconds').format('HH:mm');
			allhours.push(<li style={{ color: '#000' }}> N°{i + 1} :  {newval}</li>)
		}

		setrepet(allhours)
		setdialog(true);
	}

	return (
		<>
			<div style={{ maxWidth: '100%', display: 'flex !important' }}>
				<MaterialTable
					columns={[
						{ title: 'Nom', field: 'name' },
						{
							title: 'Chemin', field: 'url',
							render: (rowdata) => {
								return formatRoute(rowdata.url);
							},
							editComponent: props => (

								<TextField
									id="filled-number"
									type="text"
									placeholder="url"
									value={props.value}
									onChange={(e) => props.onChange(e.target.value)}
									InputLabelProps={{ shrink: true }}
									variant="standard"
								/>
							)
						},
						{
							title: 'Répétition par jour', field: 'repetition',
							render: (data) => (
								<div>{data.repetition}
									<Button onClick={() => openResumeModal(data.repetition)}><HiInformationCircle color="#ff7b00" size={24} /></Button>
								</div>
							),
							initialEditValue: 1,

							editComponent: props => (
								<>
									<TextField
										id="filled-number"
										type="number"
										placeholder="Repetition"
										value={props.value}
										onChange={(e) => props.onChange(e.target.value)}
										InputLabelProps={{ shrink: true }}
										inputProps={{
											min: "1", max: "288", step: "1",
											onKeyDown: (event) => {
												event.preventDefault();
											}
										}}
										variant="standard"
									/>
									<Button onClick={() => openResumeModal(props.value)}><HiInformationCircle color="#ff7b00" size={24} /></Button>
								</>
							)
						},
						{
							title: 'Plage interdites', field: 'plagerestriction',
							render: (data) => {
								let allHours = []

								if (!data.plagerestriction || data.plagerestriction === 'null') {
									allHours = []
								}
								else if (typeof (data.plagerestriction) === 'string') {
									allHours = JSON.parse(data.plagerestriction)['hours']
								}
								else {
									allHours = data.plagerestriction
								}
								return (<List dense={true}>
									{allHours.map((elem, key) => (
										<ListItem key={key} >
											<ListItemText primary={elem['debut'] + " - " + elem['fin']} />
										</ListItem>)
									)}
								</List>
								)
							},
							initialEditValue: [],
							editComponent: props => (
								<>
									<ListHeures data={props} />
								</>
							)
						},
					]}
					data={data}
					title="Toutes les taches"
					localization={localeFr}
					icons={tableIcons}
					options={{
						pageSize: 10,
						pageSizeOptions: [10, 25, 50, 100, 200]
					}}

					actions={[
					{
						icon: () => <ListIcon />,
						tooltip: 'Detail des crons',
						onClick: (event, rowData) => {
							setselectedtask(rowData.id);
							setcronlogsdialog(true);
						}
					},

					]}
					editable={{
						onRowAdd: newData =>
							new Promise((resolve, reject) => {
								if (newData['name'] && newData['url'] && newData['repetition']) {
									if (newData['plagerestriction']) {
										const finalTaskIds = []
										newData['plagerestriction'].forEach((elem) => {
											finalTaskIds.push(elem);
										})
										newData['plagerestriction'] = JSON.stringify({ hours: finalTaskIds });
									}

									taskServices.createTask(newData)
										.then((datanew) => {
											setdata([...data, datanew]);
											toastSuccess('Application créée');
											resolve();
										})
								}
								else {
									toastError('Tous les champs ne sont pas remplis');
									reject()
								}

							}),
						onRowUpdate: (newData, oldData) =>
							new Promise((resolve) => {
								if (newData['plagerestriction'] && typeof(newData['plagerestriction'] ) !== 'string') {
									const finalTaskIds = []
									newData['plagerestriction'].forEach((elem) => {
										finalTaskIds.push(elem);
									})
									newData['plagerestriction'] = JSON.stringify({ hours: finalTaskIds });
								}
								taskServices.updateTask(oldData.id, newData)
									.then(() => {
										const dataUpdate = [...data];
										const index = oldData.tableData.id;
										dataUpdate[index] = newData;
										setdata([...dataUpdate]);

										resolve();
									});
							}),
						onRowDelete: oldData =>
							new Promise((resolve) => {
								taskServices.deleteTask(oldData.id)
									.then(() => {
										const dataDelete = [...data];
										const index = oldData.tableData.id;
										dataDelete.splice(index, 1);
										setdata([...dataDelete]);
										resolve();
									})
							}),
					}}
				/>

			</div>
			<DialogCron cronLogsDialog={cronlogsdialog} setcronLogsDialog={setcronlogsdialog} taskid={selectedtask} />
			<Dialog
				open={dialog}
				onClose={() => setdialog(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Résumé des executions"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<ul>{repet}</ul>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setdialog(false)} autoFocus>
						Ok
					</Button>
				</DialogActions>
			</Dialog>

		</>)
}

function mapStateToProps(state) {
	const token = state.authentication.auth.access_token
	return { accessToken: token }
}

export default connect(mapStateToProps, {})(AllTasks);