import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import img from '../../img/logo.png';
import LoginForm from './components/LogInForm';
import Papyrus from "../../img/background.jpg";
import svgback from "../../img/background.svg";
import config from "../../config"
import { createTheme } from '@mui/material';
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const theme = createTheme();

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      Defi Informatique
      {' '}
      {new Date().getFullYear()}
      .
    </Typography>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    height: '100% !important',
    width: '50% !important',
  },
  image: {
    backgroundImage: `url(${Papyrus})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  papersignup: {
    margin: theme.spacing(2, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const NewPassword = (props) => {
  const {currUser} = props;
  const classes = useStyles();
	let navigate = useNavigate();

  useEffect(() => {
		if (currUser && Object.keys(currUser).length !== 0) {
			navigate("/clients");
		}
  }, [props.currUser])
  return (
    <Grid
      container
      spacing={3}
      direction="column"
      justifyContent="center"
      alignItems="center"
      className="login"
      style={{
        height: '100%',
        width: '100%',
        minHeight: '100vh',
        minWidth: '100vh',
        color: 'white',
        'background-image': `linear-gradient(135deg, ${config.backgroundColorOne} 20%, ${config.backgroundColorTwo} 80%), url(${svgback})`,
        'background-size': "100%, 100%",
      }}
    >
      <Grid container spacing={3} className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>

          <div className={classes.paper}>
            <div style={{ marginBottom: '30px' }}>
              <img alt="logo" src={img} height="75px" width="150px" />
            </div>
            <LoginForm />
            <Box mt={5}>
              <Copyright />
            </Box>
          </div>
        </Grid>
      </Grid>
    </Grid>


  );
};

function mapStateToProps(state) {
  return ({ currUser: state.user })
}

const connectedLayout = connect(mapStateToProps, {})(NewPassword)
export default connectedLayout
