/* eslint-disable no-console */

import axios from 'axios';
import config from '../../config';
import { userActions } from '../actions/userActions';
import { auth } from '../../helpers/authHeader';
import { toastError } from '../actions/alertActions';

const main = axios.create();

axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

main.defaults.baseURL = config.API_URL;
main.defaults.headers.post['Content-Type'] = 'application/json';
main.defaults.headers.put['Content-Type'] = 'application/json';

main.interceptors.request.use(
  config => {
    const token = auth.authHeader();
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  error => {
    console.log(error)
  }
);

main.interceptors.response.use(undefined, (err) => {
  const { response: { status, data } } = err;
  return Promise.resolve({ statusText: 'KO', status, message: data !== undefined ? data : err.message });
});


export const apiClient = main;

export function handleResponse(response) {
  if (response.error && response.error.message) {
    return ({ error: response.error.message });
  }
  if (response.status && response.status === 403) {
    toastError('Votre session a expiré, veuillez vous reconnecter');
    userActions.logout();
  }
  if (response.status && response.status >= 400 && response.status <= 499) {
    return (response.message)
  }
  else {
    const { data } = response;
    return data;
  }
}