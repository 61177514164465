/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect } from 'react';

import { Provider, useSelector } from 'react-redux';
import store from '../../helpers/store';

import * as serviceWorker from '../../serviceWorker';
import '../../scss/index.scss';
import LogIn from "../Login/index.jsx";
import Layout from './Layout.jsx';

import { SnackbarProvider } from 'notistack';
import Notifier from './Notifier';
import {
    Routes,
    Route,
    useLocation,
    Navigate,

} from "react-router-dom";
import Clients from '../Client/index';
import AllAps from '../AllAps/index';
import AllTasks from '../Tasks/index'

import Logs from '../Logs/index';


serviceWorker.unregister();

function RequireAuth({ children }) {
    let location = useLocation();
    const { loggedIn } = useSelector(state => state.authentication);

    if (!loggedIn) {
        return <Navigate to="/login" state={{ from: location }} />;
    }

    return children;
}

const NotFound = () => {
    let location = useLocation();
    const { loggedIn } = useSelector(state => state.authentication);

    if (!loggedIn) {
        return <Navigate to="/login" state={{ from: location }} />;
    }
    else {
        return <Navigate to="/clients" state={{ from: location }} />;
    }
}

const App = () => {

    return (
        <Provider store={store}>
            <SnackbarProvider maxSnack={10}>
                <Notifier />

                <Routes>
                    <Route path="/login" element={<LogIn />} />
                    <Route
                        path="/clients"
                        element={
                            <RequireAuth>
                                <Layout>
                                    <Clients />
                                </Layout>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/logs"
                        element={
                            <RequireAuth>
                                <Layout>
                                    <Logs />
                                </Layout>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/allapps"
                        element={
                            <RequireAuth>
                                <Layout>
                                    <AllAps />
                                </Layout>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/alltasks"
                        element={
                            <RequireAuth>
                                <Layout>
                                    <AllTasks />
                                </Layout>
                            </RequireAuth>
                        }
                    />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </SnackbarProvider>
        </Provider>
    )
}

export default (App);